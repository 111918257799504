/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import { userProfileModal } from "./UserManagement.module.scss";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import FormField from "../../common/FormField/FormField";
import { loginRequest } from "../../../authConfig";
import OverlaySpinner from "../../common/OverlaySpinner/OverlaySpinner";
import { useDispatch } from "react-redux";
import { CREATE_CONTACT } from "../../../queries/MutationQuery";
import { getHotelUserList } from "../../../redux/slices/HotelUserManagement/Action";
import useQueryParams from "../../../hooks/useQueryParams";
import { IHotel } from "../../../@types/IHotel";
import { hotelSelector } from "../../../redux/slices/Profile/ProfileSelectors";
import { useAppSelector } from "../../../hooks/redux";

const NewUserProfileModal = (props: any) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    role: "",
    personalEmail: "",
    mobileNumber: "",
    title: "",
  };
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const queryParams = useQueryParams();
  const hotelId = queryParams.get("hotelId");
  const hotelInfo: IHotel = useAppSelector(hotelSelector(hotelId));
  const toastInitial = { show: false, message: "", type: "" };
  const [toast, setToast] = useState({ ...toastInitial });
  const [isLoading, setLoading] = useState(false);
  const [isTitle, setTitle] = useState(false);

  const [createContact] = useMutation(CREATE_CONTACT, {
    onError: async () => {
      setLoading(false);
      setToast({
        show: true,
        message: "Failed to submit the form",
        type: "danger",
      });
    },
  });
  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    setFieldError,
    values,
    errors,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schema(props.existedUserRoles),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      const isHotelEmp =
        values.role === "Hotel Manager" || values.role === "Hotel Employee";
      setLoading(true);
      const token = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      localStorage.setItem("token", token.accessToken);
      const res = await createContact({
        context: {
          headers: {
            Authorization: `Bearer ${token?.accessToken}`,
          },
        },
        variables: {
          personalEmail: values?.personalEmail ?? "",
          firstName: values?.firstName ?? "",
          lastName: values?.lastName ?? "",
          mobileNumber: values?.mobileNumber ?? "",
          role: values?.role ?? "",
          hotelId: hotelInfo?.id ?? "",
          hotelBrand: hotelInfo.brand.code ?? "",
          jobTitle: isHotelEmp ? values.title : "",
        },
      });

      const { contact, errors } = res.data?.createContact;
      if (contact) {
        dispatch<any>(getHotelUserList(hotelInfo.id));
        setLoading(false);
        resetForm();
        setToast({
          show: true,
          message: "Submitted successfully!",
          type: "success",
        });
        setTimeout(() => {
          setToast(toastInitial);
          props.onHide(false);
        }, 2000);
      }
      if (errors.length !== 0) {
        setLoading(false);
        const errorMessage = errors[0].errorMessage;
        setToast({
          show: true,
          message: errorMessage,
          type: "danger",
        });
      }
      setLoading(false);
    },
  });
  const isHotelEmp =
    values.role === "Hotel Manager" || values.role === "Hotel Employee";
  const onChange = () => {
    setToast({
      show: false,
      message: "",
      type: "danger",
    });
  };
  useEffect(() => {
    if (isHotelEmp) {
      setTitle(true);
      setFieldValue("title", "");
    } else {
      setTitle(false);
      setFieldValue("title", "");
    }
  }, [values.role]);
  const handleOnChange = () => {
    setToast(toastInitial);
  };
  return (
    <>
      <Modal
        centered
        show={props.isShow}
        size="lg"
        className={userProfileModal}
        onHide={() => {
          props.onHide(false);
        }}
        backdrop={"static"}
      >
        {isLoading && <OverlaySpinner />}
        <Form onSubmit={handleSubmit} onChange={handleOnChange}>
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-user-profile"
              className="custom-modal-title"
            >
              Add New User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2">
              <Col md={6} xs={6}>
                <FormField
                  label="FIRST NAME"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  value={values.firstName}
                  error={errors.firstName}
                  handleOnChange={e => {
                    handleChange(e);
                    onChange();
                    setFieldError("firstName", "");
                  }}
                />
              </Col>
              <Col md={6} xs={6}>
                <FormField
                  label="Last NAME"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={values.lastName}
                  error={errors.lastName}
                  handleOnChange={e => {
                    handleChange(e);
                    setFieldError("lastName", "");
                  }}
                />
              </Col>
            </Row>

            <Row className="mb-1">
              <FormField
                label="ROLE"
                type="select"
                name="role"
                labelText="This is the role of the user, it will determine the permission levels for various areas of Access Point and other platforms."
                options={props.roleList}
                value={values.role}
                error={errors.role}
                handleOnChange={e => {
                  handleChange(e);
                  setFieldError("role", "");
                }}
              />
            </Row>
            {isTitle ? (
              <Row className="mb-1">
                <FormField
                  label="Title"
                  type="text"
                  name="title"
                  placeholder="Title"
                  value={values.title}
                  error={errors.title}
                  handleOnChange={e => {
                    handleChange(e);
                    setFieldError("title", "");
                  }}
                />
              </Row>
            ) : null}

            <Row className="mb-1">
              <FormField
                label="PERSONAL E-MAIL"
                type="text"
                name="personalEmail"
                placeholder="E-Mail"
                value={values.personalEmail}
                error={errors.personalEmail}
                handleOnChange={e => {
                  handleChange(e);
                  setFieldError("personalEmail", "");
                }}
              />
            </Row>
            <Row className="mb-1">
              <FormField
                label="MOBILE NUMBER"
                type="text"
                name="mobileNumber"
                placeholder="Mobile Number"
                value={values.mobileNumber}
                error={errors.mobileNumber}
                handleOnChange={e => {
                  handleChange(e);
                  setFieldError("mobileNumber", "");
                }}
              />
            </Row>

            {toast.show ? (
              <Alert variant={toast.type} className="mt-2">
                {toast.message}
              </Alert>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <div className="save-btn">
              <Button type="submit" variant="primary" size="sm">
                Save Changes
              </Button>
            </div>
            <div>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => props.onHide(false)}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default NewUserProfileModal;
export const schema = (existedUserRoles: any) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required("This field is required")
      .max(50, "Maximum 50 characters are allowed")
      .matches(/^[aA-zZ\s]+$/, "Valid field required")
      .matches(/^\S+$/, "This field cannot contain spaces"),
    lastName: yup
      .string()
      .required("This field is required")
      .max(50, "Maximum 50 characters are allowed")
      .matches(/^[aA-zZ\s]+$/, "Valid field required")
      .matches(/^\S+$/, "This field cannot contain spaces"),
    role: yup
      .string()
      .required("This field is required")
      .test(
        "is-unique",
        `The General Manager role has already been designated for this hotel.  In order to designate someone new, please first delete or change the role of the existing General Manager in the system.  Then you may proceed in adding the new General Manager.  For any questions please reach out the Sonesta Franchise Services Manager team at (855) 324-8111.`,
        function (value) {
          if (value == "General Manager") {
            return !existedUserRoles.includes(value);
          }
          return true;
        }
      ),
    personalEmail: yup
      .string()
      .email("Please enter a valid email id")
      .required("This field is required")
      .max(255, "Maximum 255 characters are allowed"),
    title: yup.string().when("role", {
      is: (role: any) => role === "Hotel Manager" || role === "Hotel Employee",
      then: yup
        .string()
        .required("This field is required")
        .matches(/^[aA-zZ\s]+$/, "Valid field required"),
    }),
    mobileNumber: yup
      .string()
      .required("This field is required")
      .matches(/^[0-9-+()]*$/, "Valid field required"),
  });
